<template>
  <!-- Cambiar a un table -->
  <div>
    <portal-target name="notificationNominasAlertPlace" />

    <!-- Lista de items -->
    <b-table
      :fields="fields"
      :items="list"
      show-empty
      responsive
      striped
      bordered
      hover
      small
      :busy="isLoading"
      :tbody-tr-class="rowClass"
    >
      <template #cell(id)="data">
        <div class="text-primary text-center text-sm w-100">
          {{ data.item }}
        </div>
      </template>
      <template #cell(concepto)="data">
        <div v-if="data.item.qna==1" class="text-primary text-center text-sm w-100">
          {{ data.item.concepto }}
        </div>
        <div v-else-if="data.item.qna==2" class="text-secondary text-center text-sm w-100">
          {{ data.item.concepto }}
        </div>
        <div v-else class="text-warning text-center text-sm w-100">
          {{ data.item.concepto }}
        </div>
      </template>
      <template #cell(qna)="data">
        <div class="text-primary text-center text-sm w-100">
          <b-badge v-if="data.item.qna==1" variant="primary" class="ml-2">Primera</b-badge>
          <b-badge v-else-if="data.item.qna==2" variant="secondary" class="ml-2">Segunda</b-badge>
          <b-badge v-else variant="warning" class="ml-2">Extraordinaria</b-badge>
        </div>
      </template>
      <template #cell(fecha)="data">
        <div class="text-primary text-center text-sm w-100">
          <b-badge variant="dark" class="ml-2">{{ data.item.fecha }}</b-badge>
        </div>
      </template>
      <template #cell(folio)="data">
        <div v-if="data.item.qna==1" class="text-primary text-center text-sm w-100">
          {{ data.item.folio }}
        </div>
        <div v-else-if="data.item.qna==2" class="text-secondary text-center text-sm w-100">
          {{ data.item.folio }}
        </div>
        <div v-else class="text-warning text-center text-sm w-100">
          {{ data.item.folio }}
        </div>
      </template>
      <template #cell(tipo)="data">
        <b-badge v-if="data.item.qna==1" variant="primary" class="ml-2">{{ data.item.tipo }}</b-badge>
        <b-badge v-else-if="data.item.qna==2" variant="secondary" class="ml-2">{{ data.item.tipo }}</b-badge>
        <b-badge v-else variant="warning" class="ml-2">{{ data.item.tipo }}</b-badge>
      </template>
      <template #cell(mes)="data">
        <div class="text-primary text-center text-sm w-100">
          <b-badge variant="secondary" class="ml-2">
            {{ getMonthById(data.item.mes).value }}
          </b-badge>
        </div>
      </template>
      <template #cell(periodo_fiscal_id)="data">
        <div class="text-primary text-center text-sm w-100">
          <b-badge variant="primary" class="ml-2">{{ data.item.periodo_fiscal_id }}</b-badge>
        </div>
      </template>
      <template #cell(total)="data">
        <div v-if="data.item.qna==1" class="text-primary text-right text-sm w-100">
          {{ $formatNumber(data.item.total) }}
        </div>
        <div v-else-if="data.item.qna==2" class="text-secondary text-right text-sm w-100">
          {{ $formatNumber(data.item.total) }}
        </div>
        <div v-else class="text-warning text-right text-sm w-100">
          {{ $formatNumber(data.item.total) }}
        </div>
      </template>

      <!-- Muestra los detalles de los movimientos -->
      <template #row-details>
        <nominas-movimientos-list :nomina="selectedNomina"></nominas-movimientos-list>
      </template>

      <template #cell(acciones)="row">
        <div class="d-flex justify-content-center align-items-center" style="font-size: 1rem">
          <b-button
            class="btn-sm btn-outline-black mr-3 mb-2 mb-sm-0"
            size="sm"
            variant="white"
            @click="
              row.toggleDetails()
              onShowMovimientos(row.item.id)
            "
          >
            Movimientos
          </b-button>

          <check-authorization :requiresAuthorizations="['importar nomina']">
            <b-button
              class="btn-sm btn-outline-info mr-3 mb-2 mb-sm-0"
              size="sm"
              variant="white"
              @click="onSelect(row.item.id)"
            >
              Importar
            </b-button>
          </check-authorization>

          <check-authorization :requiresAuthorizations="['exportar nomina']">
            <b-button
              class="btn-sm btn-outline-secondary mr-3 mb-2 mb-sm-0"
              size="sm"
              variant="white"
              @click="onExport(row.item.id, row.item.folio)"
            >
              Exportar
            </b-button>
          </check-authorization>

          <u-d-controls
            editPath="momentosContablesModule/getNominaById"
            deletePath="momentosContablesModule/deleteNominaById"
            :id="row.item.id"
            :editPermissions="['editar claves presupuestales']"
            :deletePermissions="['eliminar nomina']"
            double-confirmation
            :doubleConfirmationParams="{ confirmation: true }"
            @on-deleted="onDeleteItem"
          >
            <template #edit>
              <b-button
                class="btn-sm btn-outline-blue mr-3 mb-2 mb-sm-0"
                variant="white"
                v-b-toggle.nominasFormSidebar
                @click="onEditItem(row.item.id)"
              >
                Editar
              </b-button>
            </template>
          </u-d-controls>
        </div>
      </template>
    </b-table>

    <!-- Sidebar para importar y asociar el layout a un registro de nómina -->
    <b-sidebar
      id="importNominaPoliza"
      right
      shadow
      backdrop
      no-header
      no-close-on-backdrop
      v-model="showSidebar"
      aria-labelledby="sidebar-no-header-title"
    >
      <template #default="{ hide }">
        <!-- Form para importar layout de nómina -->
        <div class="p-3">
          <h4 id="sidebar-no-header-title">Importar layout de nómina</h4>
          <nominas-import
            v-if="Boolean(selectedNomina)"
            :nomina="selectedNomina"
            @on-result="hide()"
          >
            <template #btn-cancel-place="{clearForm}">
              <b-button
                variant="dark"
                size="sm"
                @click="
                  clearForm()
                  hide()
                "
              >
                Cerrar
              </b-button>
            </template>
          </nominas-import>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import NominasImport from '@/components/Nominas/NominasImport'
import NominasMovimientosList from '@/components/Nominas/NominasMovimientosList'

const payload = () => ({
  periodo_fiscal_id: null
})

export default {
  name: 'NominasList',

  components: {
    NominasImport,
    NominasMovimientosList
  },

  props: {
    mes: {
      type: Number,
      required: true
    }
  },

  created () {
    this.analisisDate = new Date()
    this.loadList()
  },

  data () {
    return {
      payload: payload(),

      isLoading: false,

      list: [],

      selectedNomina: null,

      showSidebar: false,

      fields: [{ key: 'qna', label: 'Quincena' }, 'concepto', 'fecha', 'folio', 'tipo', 'total', 'acciones'],
      items: []
    }
  },

  computed: {
    ...mapState('momentosContablesModule', ['nominasList']),

    ...mapGetters('momentosContablesModule', ['getNominaByMonth']),

    ...mapGetters('UIModule', ['getMonthById'])
  },

  watch: {
    nominasList: {
      immediate: true,
      deep: true,
      handler () {
        this.list = this.getNominaByMonth(this.mes) || []
      }
    }
  },

  methods: {
    async init () {
      const periodoActivo = await this.$getPeriodoFiscalActivo()

      this.payload.periodo_fiscal_id = periodoActivo.id
    },

    /**
     * Obtiene el listado de nóminas desde un state de Vuex.
     */
    async loadList () {
      this.isLoading = true

      await this.init()

      const getval = await this.$store.dispatch(
        'momentosContablesModule/listarNominas',
        this.payload
      )
      this.isLoading = false

      //
      // Emite un mensaje en forma de alerta si ha ocurrido un error
      if (getval.error) {
        this.$alert(getval, 'Nóminas.', 'notificationNominasAlertPlace', {
          status: 'danger'
        })
      }
    },

    onEditItem (id) {
      //
      // Manda un flag para indicar que debe esperar que el item cargue.
      this.$store.commit('momentosContablesModule/setWaitToEditItem', true)

      //
      // Obtiene el item desde un dispatch
      this.$store.dispatch('momentosContablesModule/getNominaById', id)
    },

    onDeleteItem (data) {
      this.$alert(data, 'Nóminas.', 'notificationNominasAlertPlace', {
        status: data.error ? 'danger' : 'success'
      })

      this.loadList()
    },

    onSelect (id) {
      this.selectedNomina = this.list.find(el => el.id === id)

      this.showSidebar = true
    },

    async onExport (id, folio) {
      const getval = await this.$store.dispatch('momentosContablesModule/exportNominaById', id)

      await this.download(getval, `nomina.${folio}.xlsx`)

      this.$alert(
        { message: `Solicitud de descarga de nómina con folio ${folio}.` },
        'Nóminas.',
        'notificationNominasAlertPlace',
        {
          status: 'info',
          closeOnTimeout: true
        }
      )
    },

    async onAutorizar (id) {
      this.isLoading = true
      const getval = await this.$store.dispatch('momentosContablesModule/autorizarNominaById', id)
      this.isLoading = false

      this.$alert(getval, 'Nóminas.', 'notificationNominasAlertPlace', {
        status: getval.error ? 'danger' : 'success',
        closeOnTimeout: true
      })
    },

    onShowMovimientos (id) {
      this.selectedNomina = this.list.find(el => el.id === id)
    },

    download (data, name) {
      const blob = new Blob([data], { type: 'blob' })
      const e = document.createEvent('MouseEvents')
      const a = document.createElement('a')

      //
      // Nombre del archivo
      a.download = name

      a.href = window.URL.createObjectURL(blob)
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
      a.dispatchEvent(e)
    },

    rowClass (item, type) {
      if (item && type === 'row') {
        if (item.qna === 1) {
          return 'primary'
        } else {
          return 'secondary'
        }
      } else {
        return null
      }
    }
  }
}
</script>
<style scoped>
.modal{
  display: block !important; /* I added this to see the modal, you don't need this */
}

/* Important part */
.modal-dialog{
  overflow-y: initial !important
}
.modal-body{
  height: 80vh;
  overflow-y: auto;
}
</style>
