<template>
  <div>
    <loading v-if="loading" :message="isEditingNomina ? 'Actualizando registro de nómina...' : 'Creando registro de nómina...'"></loading>
    <b-form v-else @submit.prevent="submit">
      <b-alert :show="processed" :variant="variant">
        {{ msg }}
      </b-alert>

      <!-- Folio -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Folio
            <span class="text-danger">*</span>
          </label>
        </template>
        <b-form-input v-model="payload.folio"></b-form-input>
      </b-form-group>

      <!-- Fecha -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Fecha
            <span class="text-danger">*</span>
          </label>
        </template>
        <date-picker
          v-model="input_fecha"
          valueType="MM/DD/YYYY"
          name="fecha"
          class="w-100"
          type="date"
        />
      </b-form-group>

      <!-- Quincena -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Quincena
            <span class="text-danger">*</span>
          </label>
        </template>
        <b-form-select
          id="input-3"
          v-model="payload.qna"
          :options="qna_list"
          required
        ></b-form-select>
      </b-form-group>

      <!-- Concepto -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Concepto
            <span class="text-danger">*</span>
          </label>
        </template>
        <b-form-input v-model="payload.concepto"></b-form-input>
      </b-form-group>

      <!-- Tipo -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Tipo
            <span class="text-danger">*</span>
          </label>
        </template>
        <b-form-select
          id="input-3"
          v-model="payload.tipo_id"
          :options="tipos_nomina_list"
          required
        ></b-form-select>
      </b-form-group>

      <!-- Submit -->
      <div class="d-flex justify-content-between">
        <slot name="btn-cancel-place" v-bind="{ clearForm }"></slot>
        <b-button type="submit" size="sm" variant="success" :disabled="!isFormVal">
          {{ isEditingNomina ? 'Actualizar' : 'Crear' }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

const payload = () => ({
  folio: null,
  fecha: null,
  qna: null,
  concepto: null,
  tipo_id: null,
  periodo_fiscal_id: null,
  mes: null
})

export default {
  name: 'NominasForm',

  components: {
    DatePicker
  },

  props: {
    mes: {
      type: Number,
      required: true
    }
  },

  created () {
    this.init()
  },

  data () {
    return {
      input_fecha: null,

      payload: payload(),

      qna_list: [
        { text: 'Primera', value: 1 },
        { text: 'Segunda', value: 2 },
        { text: 'Extraordinaria', value: 3 }
      ],

      tipos_nomina_list: [],

      variant: 'info',
      processed: false,
      msg: '',

      loading: false
    }
  },

  computed: {
    ...mapGetters('momentosContablesModule', ['isEditingNomina', 'getTiposNomina']),

    isFormVal () {
      return [
        Boolean(this.payload.folio),
        Boolean(this.payload.fecha),
        Boolean(this.payload.concepto)
      ].every(e => e)
    }
  },

  watch: {
    isEditingNomina: {
      immediate: true,

      handler (value) {
        if (!value) return

        this.payload = this.$store.state.momentosContablesModule.currentNomina
        this.input_fecha = this.payload.fecha
      }
    },

    /**
     * Formatea la fecha ingresada a MM/DD/YYYY
     */
    input_fecha (newValue) {
      const f = this.$customFormatDate(newValue, 'MM/DD/YYYY')

      this.payload.fecha = f
    }
  },

  methods: {
    init () {
      //
      // Carga el periodo fiscal
      this.payload.periodo_fiscal_id = this.$store.getters[
        'fiscalPeriodsModule/getActiveFiscalPeriod'
      ].id

      //
      // Carga el mes
      this.payload.mes = this.mes

      this.showMessage()
      //
      // Carga los tipos de nómina
      this.load()
    },

    async load () {
      this.loading = true
      const list = await this.$store.dispatch('momentosContablesModule/getTiposNomina')
      this.tipos_nomina_list = list.map(f => ({ value: f.id, text: f.nombre }))
      this.loading = false
    },

    async submit () {
      this.init()

      this.loading = true
      const getval = this.isEditingNomina
        ? await this.update(this.payload.id, this.payload)
        : await this.create(this.payload)
      this.loading = false

      //
      // Muestra una alerta si ha ocurrido un error
      this.processed = true
      this.msg = getval.message
      this.variant = getval.error ? 'danger' : 'success'

      if (getval.error) return

      this.showMessage(getval.message)
      this.emitResult(getval)
      this.clearForm()
    },

    async create (payload) {
      return await this.$store.dispatch('momentosContablesModule/crearNomina', payload)
    },

    async update (id, payload) {
      delete payload.file
      delete payload.movimientos

      return await this.$store.dispatch('momentosContablesModule/updateNominaById', { id, payload })
    },

    showMessage (msg) {
      this.$bvModal.msgBoxOk(msg, {
        title: 'Correcto',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
    },

    clearForm () {
      this.payload = payload()

      this.processed = false
      this.variant = 'info'
      this.msg = ''
      this.input_fecha = null
      this.$store.commit('momentosContablesModule/setCurrentNomina', null)
    },

    emitResult (data) {
      this.$emit('on-result', data)
    }
  }
}
</script>
